//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import Popfield from "./components/Popfield.vue";
import Popdatefield from "./components/Popdatefield.vue";
import CopyUsers from "./components/CopyUsers.vue";
// import Processfield from "./components/Processfield.vue";

export default {
  name: "Home",
  components: {
    "pop-field": Popfield,
    "pop-datefield": Popdatefield,
    "copy-users": CopyUsers,
    // "process-field": Processfield,
  },
  data() {
    return {
      saveSate: false,
      outOB: {
        hhrDeptName: "",
      },
      activeName: "3",
      enteryData: {},
      actionList: {
        CarbonCopy: {
          text: "抄送",
          color: "orange",
        },
        Approved: {
          text: "同意",
          color: "green",
        },
        Jump: {
          text: "驳回",
          color: "deep-orange",
        },
      },
      copyVal: [],
      pageData: null,
      active: 0,

      approveData: {
        value: "",
        columns: [],
        pickStatus: false,
        searchValue: "",
      },
      jumpListData: {
        value: "",
        columns: [],
        pickStatus: false,
      },
      approveAdviceValue: "",
      copyArr: [],
      copyString: "",
      processNode: 0,
      formDisable: false,
      ctfrom: false,
      hhrBiogData: {},
    };
  },
  created() {
    window.document.title = "OAdemo";
    this.onLoad();
    // this.getInitData();
  },
  mounted() {
    window.document.title = "OA流程";
    //this.wxConfig();
  },
  methods: {
    getInitData() {
      console.log("init");
    },
    //保存信息权限
    saveCtData() {
      this.enteryData._status='update'
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerWorkTransfer",
        },
        payload: JSON.stringify([this.enteryData]),
      };
      console.log('this.enteryData',this.enteryData);

      this.saveSate = true;
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: {
            processDefinitionKey: "PER_WORK_TRANSFER",
            employeeNum: this.$memberInfo.hhrEmpid,
          },
        })
        .then((res) => {
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "提交成功！",
                  timeout: 1000,
                });
           
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },
    saveFormData() {},
    onSubmit() {
      let obj = {
        approveAdviceValue: "Approved",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onRejected() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写拒绝原因",
          timeout: 1000,
        });
        return;
      }
      let obj = {
        approveAdviceValue: "Rejected",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onJumpBack() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写驳回原因",
          timeout: 1000,
        });
        return;
      }
      if (this.jumpListData.columns.length > 0) {
        this.jumpListData.pickStatus = true;
        return;
      } else {
        Toast.loading({
          message: "数据加载中...",
          duration: 0,
        });
        let taskId = this.$route.query.id;
        let postData = {
          pathVariableMap: {
            organizationId: "0",
            taskId: taskId,
          },
          headerParamMap: {},
          requestParamMap: {},
          payload: "",
        };

        this.axios
          .post("/boogoo/getJumpList", postData)
          .then((res) => {
            Toast.clear();
            if (res && res.data) {
              if (res.data.status == "200") {
                let resultData = JSON.parse(res.data.payload);
                console.log(resultData);
                if (!resultData.failed) {
                  for (let i = 0; i < resultData.length; i++) {
                    this.jumpListData.columns.push({
                      text: resultData[i].actName,
                      value: resultData[i].actId,
                    });
                  }

                  this.jumpListData.pickStatus = true;
                } else {
                  this.$q.notify({
                    color: "red-5",
                    textColor: "white",
                    icon: "warning",
                    position: "center",
                    message: resultData.message,
                    timeout: 1000,
                  });
                }
                // this.pageData = resultData;
                // this.list = resultData.content;
              } else {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: "出错了",
                  timeout: 1000,
                });
              }
            }
          })
          .catch(() => {
            Toast.clear();
            Toast("请求出错了");
          });
      }
    },
    doprocess(payload) {
      Toast.loading({
        message: "正在请求...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          taskId: this.$route.query.id,
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //当前登录人员工ID 必填
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogoo/executeTaskActionV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "204") {
              this.doCallback();
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    onLoad() {
      let taskId = this.$route.query.id;
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          taskId: taskId, //当前流程编号
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //员工ID
        },
        payload: "",
      };
      this.axios
        .post("/boogoo/queryTaskV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log(resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.pageData = resultData;
                switch (resultData.taskDefinitionKey) {
                  case "sid-kGscAFNa-6ZmD-4DNd-8KQd-FQIpHti6po0w": // 基础人事岗判断
                  case "sid-WxS1rpyu-luTQ-4QCG-8iVJ-z625cswg2z6z": // 基础人事岗判断
                    console.log("基础人事岗判断");
                    this.formDisable = false;
                    this.processNode = 2;
                    this.activeName = "3";
                    this.ctfrom = true;
                    break;
                  case "sid-u24RBQKs-OIt4-4I5B-8tfu-YQwmSbkh6PZ8": // 信息岗判断
                  case "sid-HLEFV28K-H7oO-4seB-8QLV-KmOVfEauwqd7": // 信息岗判断
                    console.log("信息岗判断");
                    this.formDisable = true;
                    this.processNode = 1;
                    this.activeName = "3";
                    this.ctfrom = true;
                    break;
                  case "sid-JQ8Pl6bc-JrjH-4j2l-8IBM-RAzRrRfVawvh": //一级领导
                    this.formDisable = true;
                    this.processNode = 0;
                    this.activeName = "3";
                    this.ctfrom = true;
                    break;
                  default:
                    this.formDisable = true;
                    this.processNode = 1;
                    this.activeName = "3";
                    this.ctfrom = true;
                }
                console.log('formDisable',this.processNode)
                this.loadFormData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadFormData() {
      let payload = [{ hhrBusinessKey: this.pageData.businessKey }];
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "query",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerWorkTransfer",
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogooForm/selectMethod", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("loadFormData", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.enteryData = resultData.content[0];
                console.log("this.enteryData", this.enteryData);
                this.loadEmpData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadEmpData() {
      let hhrpostData = {
        pathVariableMap: {
          organizationId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          hhrEmpid: this.enteryData.hhrEmpid,
        },
        payload: "{}",
      };
      this.axios
        .post("/boogoo/personInfoByhhrEmpidPost ", hhrpostData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.hhrBiogData = resultData;
                console.log("hhrBiogData", this.hhrBiogData);
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("boogoo/personInfoByhhrEmpidPost请求出错了");
        });
    },

    popConfirm(obj) {
      switch (obj.key) {
        case "hhrPosnCode":
          this.outOB.hhrDeptName = obj.value.obj.hhrDeptName;
          this.enteryData.hhrDeptCode = obj.value.obj.hhrDeptCode;
          break;
      }
      this.enteryData[obj.key] = obj.value.value;
      // console.log(obj.key,this[obj.key]);
      console.log(this.enteryData);
    },
    dateConfirm(obj) {
      this.enteryData[obj.key] = obj.value;
      console.log(this.hhrBiogData);
    },
    jumpListConfirm(obj) {
      console.log(obj);
      let payload = {
        assignee: "",
        action: "jump",
        comment: this.approveAdviceValue,
        jumpTarget: obj.value,
        jumpTargetName: obj.name,
        currentTaskId: this.$route.query.id,
      };
      this.doprocess(payload);
    },
    doCallback() {
      this.$q.notify({
        color: "primary",
        textColor: "white",
        type: "positive",
        position: "center",
        message: "操作成功",
        timeout: 1500,
      });
      this.$router.replace("/oaplatform/list");
    },
    confirmCopy(obj) {
      this.copyArr = obj;
      let tempCopy = [];
      for (let i = 0; i < this.copyArr.length; i++) {
        tempCopy.push(this.copyArr[i].value);
      }
      this.copyString = tempCopy.toString();
      console.log("confirmCopy", this.copyArr);
      console.log("copyString", this.copyString);
    },
  },
};
